// THIS VALUES ARE USED DURING CLIENT APP COMPILATION, PLEASE DO NOT MOVE
const initialAppId = 'app_kEQE4w5jUi9N7dgEa1Luyt'
const initialProjectId = 'proj_2VJ6umfcoCqGi8u59voAvm'
const initialBuildOrdersOnApp = require('./buildOrders.loaded.json') // eslint-disable-line
const initialFlattedControls = { ...require('./flattedControls.loaded.0.json') } // eslint-disable-line
const initialRunningMode = 'premium'
const initialCustomFontName = 'CustomFont' // eslint-disable-line
// #################

export const initialAppState = {
    appId: initialAppId,
    projectId: initialProjectId,
    buildOrders: initialBuildOrdersOnApp,
    flattedControls: initialFlattedControls,
    runningMode: initialRunningMode,
    customFontName: initialCustomFontName,
} as const
